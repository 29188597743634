
/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit/2 $spacing-unit/2 $spacing-unit*2 $spacing-unit/2;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    padding: 20px;

    a.-logo {
      display: block;
      line-height: 1;

      svg {
        width: 50px;
        height: 50px;
        overflow: visible;
        color: black;
        // stroke-width: 2px;
        transition: all 100ms $ease;
      }

      &:hover {
        svg {
          color: var(--primary-color);
        }
      }
    }

    .edit-license {
      border-top: 1px solid black;
      flex-basis: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-top: 20px;

      a {
        display: block;
        color: black;
        font-size: 16px;

        &:visited {
          color: #808080;
        }

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    nav.social-media-list {
      display: flex;
      align-items: center;
      margin-left: auto;

      a {
        display: flex;
        line-height: 1;

        svg {
          width: 30px;
          height: 30px;
          transition: all 150ms $ease;
        }

        &:visited {
          svg {
            fill: #808080 !important;
          }
        }

        &:hover {
          svg {
            fill: var(--primary-color) !important;
          }
        }

        & + a {
          margin-left: 20px;
        }
      }
    }

    nav.footer-nav {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      order: 5;
      flex-basis: 100%;
      border-top: 1px solid black;
      margin-top: 20px;
      padding-top: 20px;

      a {
        display: inline-block;
        color: black;
        font-size: 16px;

        &:visited {
          color: #808080;
        }

        &:hover,
        &.active {
          color: var(--primary-color);
        }
      }

      &:empty {
        display: none;
      }
    }
  }

  @include media-query(tiny) {
    .wrapper {
      .edit-license {
        a {
          font-size: 13px;
        }
      }

      nav.footer-nav {
        a {
          font-size: 13px;
        }
      }
    }
  }

  @include media-query(small) {
    .wrapper {
      nav.social-media-list {
        a {
          & + a {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

body.sidebar {
  .site-footer {
    padding-top: 30px;
    border-top: 1px solid rgba(var(--front), 0.1);
  }
}
