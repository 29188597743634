
.next-previous {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.--end {
    justify-content: flex-end;
  }

  &--feedback {
    position: relative;

    button {
      appearance: none;
      background-color: transparent;
      border-width: 0;
      font-size: 18px;
      color: rgba(black, 0.72);
      transition: all 150ms $ease;

      &:hover {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }

    &__wrap {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 14px;
        height: 14px;
        transform:  translate(-50%, 8px) rotate(-45deg);
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        background-color: white;
      }
    }

    &__options {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%, -15px);
      background-color: white;
      border: 1px solid black;
      padding: 15px;

      a {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 18px;
        white-space: nowrap;
        color: rgba(black, 0.72);
        transition: all 150ms $ease;

        svg {
          transition: all 150ms $ease;
        }

        &:hover {
          color: var(--primary-color);
          text-decoration: none;

          svg {
            fill: var(--primary-color) !important;
          }
        }

        & + a {
          margin-top: 5px;
        }
      }
    }
  }

  > a {
    display: flex;
    border: 1px solid black;
    color: black;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 100ms $ease;

    svg {
      width: 24px;
      height: 24px;
      transition: all 100ms $ease;
    }

    & + a {
      margin-left: 15px;
    }

    &.next-previous--previous {
      padding: 14px 17px;

      svg {
        transform: rotate(180deg);
      }
    }

    &.next-previous--next {
      align-self: flex-end;
      padding: 14px 20px 14px 25px;

      svg {
        margin-left: 2px;
      }
    }

    &:hover {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  @include media-query(small) {
    flex-wrap: wrap;

    .next-previous--feedback {
      display: flex;
      order: 3;
      flex-basis: 100%;
      justify-content: center;
      padding-top: 25px;

      &__options {
        transform: translate(-50%, 5px);
      }
    }
  }
}
