

//
// Main nav, breadcrumb, etc...
//
// stylelint-disable selector-no-type, max-nesting-depth, selector-max-compound-selectors, selector-max-type, selector-max-specificity

.nav-list {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;

  .nav-list-divider {
    height: 1px;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #dedede;
    box-sizing: border-box;
  }

  .nav-list-category {
    padding-left: 30px;
    font-size: 15px;
    line-height: 30px;
    color: #707070;
  }

  .nav-list-item {
    position: relative;
    margin: 0;

    a.nav-list-link {
      display: block;
      font-size: 15px;
      line-height: 30px;
      margin-left: 30px;
      color: black;

      &:visited {
        color: black;
      }

      &.-active {
        font-weight: 500;
        color: var(--primary-color);
        text-decoration: none;

        &:visited {
          color: var(--primary-color);
        }
      }

      &:hover {
        color: var(--primary-color);
      }

      &:hover,
      &.-active {
        text-decoration: none;
      }
    }

    .nav-list-expander {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: transparent;
      border-width: 0;

      svg {
        width: 15px;
        height: 15px;
        color: var(--link-base-color);
        transition: all 150ms $ease;
      }

      &:active,
      &:focus-visible {
        border-width: 0;
        background-color: rgba(var(--primary-color-rgb), 0.1);
      }

      &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.1);

        svg {
          color: var(--primary-color);
        }
      }
    }

    > .nav-list {
      display: none;
      list-style: none;
      padding-left: 15px;

      .nav-list-item {
        position: relative;
        cursor: pointer;

        > .nav-list {
          display: block;
        }

        > a.nav-list-link {
          color: #474747;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    &.-active {
      > a.nav-list-link {
        color: var(--primary-color);

        &:visited {
          color: var(--primary-color);
        }
      }

      > .nav-list-expander {
        svg {
          transform: rotate(90deg);
          color: var(--primary-color);
        }
      }

      > .nav-list {
        display: block;
      }
    }
  }
}
