article.guide {
  .product-feature {
    margin-bottom: $spacing-unit;
    margin-top: $spacing-unit;

    @include media-query(medium-up) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    figure {
      min-width: 100px;
      flex-basis: 100px;
    }

    div {
      width: auto;
      flex-basis: auto;
    }

    .h4 {
      margin-bottom: 0;
      &:before {
        margin-top: 0;
        height: auto;
      }
    }

    p {
      margin-top: 0;
    }
  }
}

.daily-spending-wallet-landing article.guide {
  h2, h3 {
    margin-top: 30px;

    &:before {
      display: none;
    }
  }

  .-float-left {
    img {
      border-radius: 8px;
    }
  }
}
