

.home-banner {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--banner-background-color);
  background-image: var(--banner-background-path-mobile);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  transition: background-color 150ms $ease;

  @include retina() {
    background-image: var(--banner-background-path-mobile-retina);
  }

  .home-banner-copy-wrapper {
    background-color: var(--copy-background-color);
    transition: background-color 150ms $ease;
    padding: 30px 15px;

    h1 {
      font-family: $base-font-family;
      font-style: normal;
      font-weight: bold;
      line-height: 100%;
      letter-spacing: -0.03em;
      transition: all 150ms $ease;
      @include responsive('font-size', 48, 64);

      color: black;
      -webkit-text-fill-color: var(--title-fill-color); /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--title-outline-color);
    }

    p {
      color: var(--description-fill-color);
      font-family: $base-font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 30px;
      transition: color 150ms $ease;
    }

    a {
      display: inline-block;
      padding: 10px 20px;
      text-decoration: none;
      box-sizing: border-box;
      filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.05));
      border-radius: 5px;
      margin: 0 0 12px 0;
      font-weight: 600;
      transition: all 150ms $ease;
    }

    #link-button-1 {
      background-color: var(--button-1-fill-color);
      border: 1px solid var(--button-1-outline-color);
      color: var(--button-1-label-fill-color);
    }

    #link-button-2 {
      background-color: var(--button-2-fill-color);
      border: 1px solid var(--button-2-outline-color);
      color: var(--button-2-label-fill-color);
    }

    .home-banner-copy {
      max-width: 500px;

      .home-banner-logo-wrapper {
        margin-bottom: 20px;

        svg {
          overflow: visible;
        }
      }
    }
  }

  .home-banner-image {
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--banner-image-color);
    background-image: var(--banner-image-path-mobile);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    transition: background-color 150ms $ease;
    position: relative;

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    @include retina() {
      background-image: var(--banner-image-path-mobile-retina);
    }
  }

  @include media-query(medium-down) {
    &.-flip-on-mobile {
      flex-direction: column;
    }

    .home-banner-copy-wrapper {
      .home-banner-copy {
        .home-banner-logo-wrapper {
          display: none;
        }

        h1 {
          margin-top: 0;
        }
      }
    }
  }

  @include media-query(large) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-image: var(--banner-background-path);
    background-position: center center;

    @include retina() {
      background-image: var(--banner-background-path-retina);
    }

    .home-banner-image {
      background-image: var(--banner-image-path);

      @include retina() {
        background-image: var(--banner-image-path-retina);
      }
    }

    .home-banner-copy-wrapper {
      width: 50%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        margin: 0 12px 0 0;
      }
    }

    .home-banner-buttons-wrapper {
      display: flex;
      flex-direction: row;
    }

    .home-banner-image {
      width: 50%;
      padding-bottom: 50%;
    }
  }
}

.home-banner-info {
  display: flex;
  justify-content: center;
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f4f4f4;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  .home-banner-info-wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    > p {
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      flex-basis: 50%;
      padding-right: 12px;

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .home-banner-info-options {
      display: flex;
      align-items: start;
      flex-basis: 50%;
      justify-content: flex-end;

      a,
      button {
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: black;
        text-decoration: underline;

        &:hover {
          color: var(--primary-color);
        }
      }

      button {
        appearance: none;
        background-color: transparent;
        border-width: 0;
        margin-top:0;
        padding-top: 0;
      }

      a + button {
        margin-left: 10px;
      }
    }
  }

  @include media-query(tiny) {
    .home-banner-info-wrapper {
      > p {
        font-size: 10px;
        line-height: 16px;
      }

      .home-banner-info-options {

        a,
        button {
          font-size: 10px;
          line-height: 16px;
        }

        a + button {
          margin-left: 5px;
        }
      }
    }
  }
}

.lottie-active {
  .home-banner {
    .home-banner-image {
      &.lottie {
        background-image: none;
      }
    }
  }
}
