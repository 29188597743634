@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$heavy-font-weight: 500 !default;
$light-font-weight: 100 !default;
$small-font-size:  $base-font-size * 0.75 !default;
$large-font-size:  $base-font-size * 1.875 !default;
$base-line-height: 1.7 !default;

$spacing-unit:     30px !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1340px !default;

// For media queries.
$tiny_width:               375px;
$small_width:              640px;
$large_width:              1024px;
$huge_width:               1440px;

$yellow: #FFF75C;
$bitcoin-yellow: #F7931A;
$bitcoin-yellow-contrast: #DB821A;
$blue: #6490E3;
$green: #6FCF97;
$red: #DE5E57;
$primary-color: $bitcoin-yellow;

:root {
  --back: 251, 251, 251;
  --front: 0, 0, 0;

  --backHex: #fbfbfb;
  --frontHex: #000000;

  --primary-color: #F7931A;
  --primary-color-rgb: 247, 147, 26;
  --primary-color-h: 33;
  --primary-color-s: 93%;
  --primary-color-l: 54%;
  --primary-color-l-darker: 44%;

  --link-base-color: var(--primary-color);
  --link-hover-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l-darker));
  --link-visited-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l-darker));

  @media (prefers-contrast: more) {
    --primary-color: #9c5c19;
    --primary-color-rgb: 156, 92, 25;
    --primary-color-h: 31;
    --primary-color-s: 72%;
    --primary-color-l: 35%;
    --primary-color-l-darker: 25%;
    
    .guide a {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 1px;
    }
  }
}

.theme--dark {
  --back: 13, 13, 15;
  --front: 255, 255, 255;

  --backHex: #0D0D0F;
  --frontHex: #ffffff;
}



$white: #fff !default;

$grey-dk-000: #959396 !default;
$grey-dk-100: #5c5962 !default;
$grey-dk-200: #44434d !default;
$grey-dk-250: #302d36 !default;
$grey-dk-300: #27262b !default;

$grey-lt-000: #f5f6fa !default;
$grey-lt-100: #eeebee !default;
$grey-lt-200: #ecebed !default;
$grey-lt-300: #e6e1e8 !default;

$purple-000: #7253ed !default;
$purple-100: #5e41d0 !default;
$purple-200: #4e26af !default;
$purple-300: #381885 !default;

$blue-000: #2c84fa !default;
$blue-100: #2869e6 !default;
$blue-200: #264caf !default;
$blue-300: #183385 !default;
$blue-900: #0f284c !default;

$green-000: #41d693 !default;
$green-100: #11b584 !default;
$green-200: #009c7b !default;
$green-300: #026e57 !default;

$yellow-000: #ffeb82 !default;
$yellow-100: #fadf50 !default;
$yellow-200: #f7d12e !default;
$yellow-300: #e7af06 !default;
$yellow-400: #f7b93f !default;

$red-000: #f77e7e !default;
$red-100: #f96e65 !default;
$red-200: #e94c4c !default;
$red-300: #dd2e2e !default;

$body-background-color: $white !default;
$sidebar-color: $grey-lt-000 !default;
$search-background-color: $white !default;
$table-background-color: $white !default;
$code-background-color: $grey-lt-000 !default;
$feedback-color: darken($sidebar-color, 3%) !default;

$body-text-color: $grey-dk-100 !default;
$body-heading-color: $grey-dk-300 !default;
$search-result-preview-color: $grey-dk-000 !default;
$nav-child-link-color: $grey-dk-100 !default;
$link-color: $purple-000 !default;
$btn-primary-color: $purple-100 !default;
$base-button-color: #f7f7f7 !default;


$spacers: (
  sp-0: 0,
  sp-1: $spacing-unit * 0.25,
  sp-2: $spacing-unit * 0.5,
  sp-3: $spacing-unit * 0.75,
  sp-4: $spacing-unit,
  sp-5: $spacing-unit * 1.5,
  sp-6: $spacing-unit * 2,
  sp-7: $spacing-unit * 2.5,
  sp-8: $spacing-unit * 3,
  sp-9: $spacing-unit * 3.5,
  sp-10: $spacing-unit * 4,
) !default;

$sp-1: map-get($spacers, sp-1) !default; // 0.25 rem == 4px
$sp-2: map-get($spacers, sp-2) !default; // 0.5  rem == 8px
$sp-3: map-get($spacers, sp-3) !default; // 0.75 rem == 12px
$sp-4: map-get($spacers, sp-4) !default; // 1    rem == 16px
$sp-5: map-get($spacers, sp-5) !default; // 1.5  rem == 24px
$sp-6: map-get($spacers, sp-6) !default; // 2    rem == 32px
$sp-7: map-get($spacers, sp-7) !default; // 2.5  rem == 40px
$sp-8: map-get($spacers, sp-8) !default; // 3    rem == 48px
$sp-9: map-get($spacers, sp-9) !default; // 3.5  rem == 56px
$sp-10: map-get($spacers, sp-10) !default; // 4  rem == 64px

//
// Borders
//

$border: 1px solid !default;
$border-radius: 4px !default;
$border-color: $grey-lt-100 !default;

//
// Grid system
//

$gutter-spacing: $sp-6 !default;
$gutter-spacing-sm: $sp-4 !default;
$nav-width: 264px !default;
$nav-width-md: 248px !default;
$nav-list-item-height: $sp-6 !default;
$nav-list-item-height-sm: $sp-8 !default;
$nav-list-expander-right: true;
$content-width: 800px !default;
$header-height: 60px !default;
$search-results-width: $content-width - $nav-width !default;
$transition-duration: 400ms;

//
// Media queries in pixels
//

$media-queries: (
  xs: 320px,
  sm: 500px,
  md: $content-width,
  lg: $content-width + $nav-width,
  xl: 1400px,
) !default;

$ease  : cubic-bezier(0.5, 0.0, 0.2, 1);
