
#units-and-symbols-formatter {
    background-color: #777777;
    margin-top: 30px;
    padding: 30px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    .units-and-symbols-formatter-top {
        display: flex;

        label {
            margin: 0;
            padding: 0;
            display: block;
            font-size: 16px;
            color: #D2FA7B;
        }

        input {
            display: block;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-size: 48px;
            appearance: none;
            border-width: 0;
            color: #D2FA7B;
            width: 100%;
            background-color: transparent;
            -moz-appearance: textfield;
            border-bottom: 1px dashed #D2FA7B;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus-visible {
                outline: none;
            }
        }
    }

    #units-and-symbols-formatter-table {
        div {
            display: flex;
            padding-top: 5px;
            padding-bottom: 5px;
            gap: 10px;

            p {
                margin: 0;
                padding: 0;
                flex-grow: 1;
                font-size: 16px;
                flex-basis: 30%;
                color: #EBEBEB;

                &:first-child {
                    flex-grow: 1;
                }

                &:last-child {
                    color: white;
                    text-align: right;
                }
            }

            & + div {
                border-top: 1px solid #999999;
            }
        }
    }

    @include media-query(small) {
        .units-and-symbols-formatter-top {
            flex-grow: 1;
            flex-basis: 30%;
            flex-direction: column;
        }

        #units-and-symbols-formatter-table {
            margin-top: 20px;

            div {
                p {
                    font-size: 14px;
                    flex-basis: 20%;

                    &:first-child {
                        flex-basis: 40%;
                    }

                    &:last-child {
                        flex-basis: 40%;
                    }
                }
            }
        }
    }

    @include media-query(medium-up) {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .units-and-symbols-formatter-top {
            flex-grow: 1;
            flex-basis: 30%;
            flex-direction: column;
        }

        #units-and-symbols-formatter-table {
            flex-basis: 50%;
            flex-grow: 1;
        }
    }
}
