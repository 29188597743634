
.image-grid {

    figure {
        display: block;
        margin: 0 !important;
        padding: 30px;
        border-left: 1px solid #dedede;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #dedede;

        p {
            margin-top: 5px;
            margin-bottom: 0px;
        }
    }

    @include media-query(small) {
        margin: $spacing-unit 0 0 0;

        figure {
            &:first-child {
                border-top: 1px solid #dedede;
            }
        }
    }

    @include media-query(medium-up) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: $spacing-unit 0 0 0;

        figure {
            flex-basis: 50%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            p {
                margin-top: 5px;
                margin-bottom: 0px;
                flex-grow: 1;
            }

            &:first-child,
            &:nth-child(2) {
                border-top: 1px solid #dedede;
            }

            &:nth-child(2n) {
                border-left-width: 0;
            }
        }
    }
}
