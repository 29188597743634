@charset "utf-8";

// Import pre-styling-overrides hook and style-partials.
@import
  "minima/mixins",
  "minima/base",             // Defines element resets.
  "minima/layout",           // Defines structure and style based on CSS selectors.
  "minima/inter",            // Font
  "minima/site-header",      // The global site header, mobile menu, and search box
  "minima/site-footer",      // The global site footer
  "minima/side-bar",         // Side bar for guide pages
  "minima/nav-list",         // Navigation list for guide page side bar and mobile menu guide sub nav
  "minima/next-previous",
  "minima/article",          // Formatting for all article pages (guide, post, page)
  "minima/article/_dl",
  "minima/article/_do",
  "minima/article/_fact",
  "minima/article/_footnotes",
  "minima/article/_image-gallery",
  "minima/article/_image-slide-gallery",
  "minima/article/_tip",
  "minima/article/_emoji-box",
  "minima/home-page",       // Formatting for the home page
  "minima/guide-page",       // Formatting for the guide landing page
  "minima/search-page",       // Formatting for the search page
  "minima/projects-page",       // Formatting for the projects page
  "minima/design-resources-page",       // Formatting for the design resources page
  "minima/units-and-symbols-page",       // Formatting for the units and symbols page
  "minima/glossary-page",       // Formatting for the glossary page
  "minima/home-banner",
  "minima/skins/error-theme",
  "minima/principles-page",
  "minima/liquidity-page",
  "minima/daily-spending-wallet-landing"
;
