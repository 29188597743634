//
// Search 
//

.search-page {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @include responsive('padding-top', 30, 60);
  @include responsive('padding-bottom', 30, 60);

  form {
    input {
      display: block;
      width: 100%;
      height: 60px;
      border: 1px solid rgba(var(--front), 0.2);
      font-size: 27px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      &:active,
      &:focus-visible {
        border-color: var(--primary-color);
        outline: none;
      }

      &::placeholder {
        color: #cccccc;
      }
    }
  }

  > ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0;

    li {
      border-bottom: 1px solid #ededed;

      h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1.2;
        color: var(--frontHex);
        transition: color 100ms $ease;

        span {
          margin-left: 5px;
          vertical-align: middle;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          transform: translateY(-1px);
        }
      }

      a {
        display: block;
        padding: 15px 0;
        transition: background-color 100ms $ease;

        p {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.4;
            color: rgba(var(--front), 0.7);
        }

        &:hover {
            text-decoration: none;
            background-color: rgba(var(--primary-color-rgb), 0.05);

            h3 {
                color: var(--primary-color);
            }
        }
      }

      &.no-results {
        padding: 15px 0;
      }
    }
  }

  #search-tips {
    padding: 15px 0;

    button {
      appearance: none;
      background-color: transparent;
      border-width: 0;
      padding: 0;
      display: flex;
      gap: 3px;
      font-size: 15px;
      align-items: center;
      transition: all 150ms $ease;

      svg {
        width: 14px;
        height: 14px;
        transition: all 150ms $ease;
      }

      &:hover {
        color: var(--primary-color);
        cursor: pointer;
      }

      &[aria-expanded="true"] {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  #search-tips-content {
    p {
      font-size: 15px;
    }

    ul {
      margin-top: 5px;

      li {
        font-size: 15px;

        em {
          font-style: normal;
          font-weight: 500;
          color: var(--front);
        }
      }

      & + p {
        margin-top: 5px;
      }
    }
  }
}
